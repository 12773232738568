<template>
  <div class="loading">
    <img src="@/assets/images/loading.gif" alt="loading">
  </div>
</template>

<script>
export default {
  data () {
    return {
      id: 0,
      type: ''
    }
  },
  created () {
    const { id, type } = this.$route.params
    if (id && type) {
      this.id = id
      this.type = type
      this.toReport()
    } else {
      this.$router.redirect('/')
    }

  },
  methods: {
    toReport () {
      const that = this
      setTimeout(() => {
        that.$router.replace(`/${that.type}/result/${that.id}`)
      }, 2000)
    }
  }
}
</script>

<style lang="scss" scoped>
.loading {
  position: relative;
  background-color: #6790f5;
  position: relative;
  width: 100vw;
  height: 100vh;
  padding: 10%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 40%;

  }
}
</style>
